const uploader = (event, { object }) => {
  object.uploadDisabled = true
  const reader = new FileReader()
  reader.readAsDataURL(event.files[event.files.length - 1])
  reader.onload = function () {

    object['picture'] = reader.result
    object.uploadDisabled = false
  }
  reader.onerror = function () {
    object.uploadDisabled = false
  }
}

const uploadRemove = ({ oldPicture, object }) => {
  if (oldPicture !== null) object['picture'] = oldPicture
  else delete object['picture']
}

export default {
  uploader,
  uploadRemove
}
