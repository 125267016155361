<script setup>

import { onMounted, reactive, ref } from "vue"
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast"

import { useAuthStore } from "@/store/auth"
import { useUserStore } from "@/store/user"

import fileUploadImage from "@/scripts/fileUploadImage"

require('dotenv').config()
const VUE_APP_NO_IMAGE = process.env.VUE_APP_NO_IMAGE

const authStore = useAuthStore()
const userStore = useUserStore()

const route = useRoute()
const toast = useToast()

const user = reactive({})
const loading = ref(true)

const password = ref(null)
const password_confirmation = ref(null)
const loadingPassword = ref(false)

const isAdmin = authStore.user.role == 'admin'
const isOwner = authStore.user.role == 'owner'

const permissions = authStore.permissions

let oldPicture = null

const changePassword = () => {
  loadingPassword.value = true

  userStore.password(route.params.id, { password: password.value, password_confirmation: password_confirmation.value }).then(({ data }) => {
    loadingPassword.value = false

    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    password.value = null
    password_confirmation.value = null
    toast.add({severity:'success', summary: 'Success', detail: 'Password Changed.', life: 3000})
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

const initial = (data) => {
  for (let key in data.user) {
    if (key == 'active') {
      user[key] = data.user[key] ? 'Active' : 'Deactive'
    } else if (key == 'fingerprint_bypass') {
      user[key] = data.user[key] ? 'Yes' : 'No'
    } else {
      user[key] = data.user[key]

      if (key == 'avatar_url') {
        user['picture'] = data.user['avatar_url'] || VUE_APP_NO_IMAGE
				oldPicture = data.user['avatar_url'] || VUE_APP_NO_IMAGE
      }
    }
  }

  user.uploadDisabled = false
}

const save = () => {
  loading.value = true

  userStore.update(route.params.id, user).then(({ data }) => {
    loading.value = false

    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    initial(data)

    authStore.setUser(data.user)

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

onMounted(() => {
  loading.value = true
  userStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    initial(data)

    loading.value = false
  })
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
      <span class="block-title">
        <div>Edit #{{ user.id }}</div>
      </span>

      <div class="flex align-items-center justify-content-center">
        <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
      </div>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div>
            <Image v-if="user.picture" :src="user.picture" preview alt="Image Text" class="mb-3" style="width: 100%"/>
            <FileUpload
              accept="image/*"
              :customUpload="true"
              :auto="true"
              :showUploadButton="false"
              :disabled="user.uploadDisabled"
              @uploader="fileUploadImage.uploader($event, { object: user })"
              @clear="fileUploadImage.uploadRemove({ oldPicture, object: user })"
            />

            <h6>Information</h6>
            <label class="block text-900 font-medium mb-2" for="role">Role</label>
            <Dropdown v-model="user.role" id="role" class="w-full mb-3" :options="['owner', 'admin', 'guest']" :disabled="isAdmin" />

            <label class="block text-900 font-medium mb-2" for="email">Email</label>
            <InputText v-model="user.email" id="email" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="username">Username</label>
            <InputText v-model="user.username" id="username" type="text" class="w-full mb-3" :readonly="isAdmin" />

            <label class="block text-900 font-medium mb-2" for="name">Name</label>
            <InputText v-model="user.name" id="name" type="text" class="w-full mb-3" :readonly="isAdmin" />

            <div v-if="isOwner && user.role !== 'owner'">
              <label class="block text-900 font-medium mb-2" for="name">Permissions</label>
              <MultiSelect
                v-model="user.permissions"

                class="w-full mb-3"
                :options="permissions"
                placeholder="Select Permission"
              />
            </div>

            <div v-if="isOwner">
              <label class="block text-900 font-medium mb-2" for="active">Active</label>
              <SelectButton v-model="user.active" id="active" class="w-full mb-3" :options="['Active', 'Deactive']" :disabled="isAdmin" />

              <label class="block text-900 font-medium mb-2" for="fingerprint_bypass">Bypass Lock ID</label>
              <SelectButton v-model="user.fingerprint_bypass" id="fingerprint_bypass" class="w-full mb-3" :options="['Yes', 'No']" :disabled="isAdmin" />

              <label class="block text-900 font-medium mb-2" for="fngerprint">Lock ID</label>
              <InputText v-model="user.fingerprint" id="fngerprint" type="text" class="w-full mb-3" :disabled="isAdmin" readonly />
            </div>
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="loading" @click="save" />
        </div>
      </div>
    </div>

    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div>
            <h6>Password</h6>

            <label class="block text-900 font-medium mb-2" for="password">Password</label>
            <InputText v-model="password" id="password" type="password" class="w-full mb-3" />

            <label class="block text-900 font-medium mb-2" for="password_confirmation">Password Confirm</label>
            <InputText v-model="password_confirmation" id="password_confirmation" type="password" class="w-full mb-3" />

          </div>

          <br/>

          <Button label="Change Password" class="w-full" :loading="loadingPassword" @click="changePassword" />
        </div>
      </div>
    </div>
  </div>
</template>
